import React from "react";
import { useTheme } from "styled-components";
import {
  FeaturedHeroContainer,
  FeaturedHeroInner,
  ContentContainer,
  MediaContainer,
} from "./FeaturedHero.styles";
import { Padding } from "../../design-system/Container/Container";
import { H1Xl } from "../../design-system/Typography/headings/Headings";

export interface FeaturedHeroProps {
  heading: string;
  children?: JSX.Element | React.ReactNode | string;
  image?: JSX.Element | React.ReactNode | string;
  videoUrl?: string;
  videoPoster?: string;
  muteVideo?: boolean;
  autoPlay?: boolean;
  loopVideo?: boolean;
  showControls?: boolean;
  doNotCropVideo?: boolean;
}

export function FeaturedHero({
  heading,
  children,
  image,
  videoUrl,
  videoPoster,
  doNotCropVideo = false,
  muteVideo,
  autoPlay,
  loopVideo,
  showControls,
  ...props
}: FeaturedHeroProps) {
  const media = videoUrl ? (
    <video
      muted={muteVideo}
      controls={showControls}
      playsInline
      autoPlay={autoPlay}
      loop={loopVideo}
      poster={videoPoster}
    >
      <source src={videoUrl} type="video/mp4" />
    </video>
  ) : (
    image
  );
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <FeaturedHeroContainer
      fullWidth
      padding={Padding.both}
      {...props}
      className={isDark ? "dark-background" : ""}
    >
      <FeaturedHeroInner>
        <ContentContainer>
          <H1Xl>{heading}</H1Xl>
          {children}
        </ContentContainer>

        <MediaContainer $videoNoCrop={doNotCropVideo}>{media}</MediaContainer>
      </FeaturedHeroInner>
    </FeaturedHeroContainer>
  );
}
