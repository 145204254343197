import React from "react";
import { StyledHeroContainer } from "../Hero.styles";
import { Padding } from "../../design-system/Container/Container";
import {
  HeroInner,
  ContentContainer,
  MediaContainer,
} from "./BackgroundHero.styles";
import {
  ImageOrder,
  ImageOrdering,
  ImagePosition,
  ImagePositions,
} from "../../_shared";
import { H1Xl } from "../../design-system/Typography/headings/Headings";
import { useBreakpointMdDown } from "../../../hooks/useBreakpoint";

type ContentAlignment = "flex-start" | "center" | "flex-end";

export interface BackgroundHeroProps {
  heading: string;
  children?: JSX.Element | React.ReactNode | string;
  image: JSX.Element | React.ReactNode;
  mobileImage?: JSX.Element | React.ReactNode;
  contentAlign: ContentAlignment;
  contentWidth?: string;
  fullHeight?: boolean;
  isDark?: boolean;
}

export function BackgroundHero({
  heading,
  children,
  image,
  mobileImage,
  fullHeight = true,
  contentAlign = "center",
  contentWidth,
  isDark = true,
  ...props
}: BackgroundHeroProps) {
  const showMobImg = mobileImage !== undefined;
  const isMobile = useBreakpointMdDown();

  return (
    <StyledHeroContainer
      fullWidth
      padding={fullHeight ? Padding.horizontal : Padding.both}
      $fullHeight={fullHeight}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <HeroInner $align={contentAlign}>
        <ContentContainer
          $align={contentAlign}
          $isDark={isDark}
          $contentWidth={contentWidth}
        >
          <H1Xl darkTheme={isDark} center={contentAlign === "center" && true}>
            {heading}
          </H1Xl>
          {children}
        </ContentContainer>
      </HeroInner>
      <MediaContainer>
        {isMobile && showMobImg ? mobileImage : image}
      </MediaContainer>
    </StyledHeroContainer>
  );
}
