import styled, { css } from "styled-components";
import { Container } from "../../design-system/Container/Container";
import { sizes } from "../../../theme/theme";

export const FeaturedHeroContainer = styled(Container)`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: Max(100vh, 790px);
    padding-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    min-height: Max(70vh, 720px);
  }
`;

export const FeaturedHeroInner = styled.div`
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 1rem;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-bottom: 4rem;

  > div {
    justify-content: center;
  }

  .block-img {
    img {
      width: auto;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
    max-width: ${sizes.sm};
    margin-bottom: 0;
    > div {
      justify-content: left;
    }
  }
`;

const videoNoCrop = css`
  display: flex;
  align-items: center;
  justify-content: center;
  img,
  video {
    position: relative;
    height: auto;
    width: 100%;
  }
`;

const videoCrop = css`
  img,
  video {
    position: absolute;
    top: 0;
    left: 5rem;
    height: 100%;
    width: auto;
  }
`;

interface MediaContainerProps {
  $videoNoCrop: boolean;
}

export const MediaContainer = styled.div<MediaContainerProps>`
  position: relative;
  width: 100%;

  img {
    max-width: unset;
  }

  video {
    width: 100%;
    height: auto;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
    min-height: 60vh;

    ${({ $videoNoCrop }) => ($videoNoCrop ? videoNoCrop : videoCrop)};
  }
`;
