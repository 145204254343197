import styled from "styled-components";

interface SimpleHeroInnerProps {
  $contentWidth?: string;
}

export const SimpleHeroInner = styled.div<SimpleHeroInnerProps>`
  text-align: center;
  width: 100%;
  max-width: ${({ $contentWidth }) =>
    $contentWidth ? $contentWidth : "680px"};
`;
